// console.log('Got to edit modal javascript');

import Sortable from 'sortablejs';
import intializeBootstrapTooltips from '../scripts/tooltips';
import { createAlert } from "../scripts/createAlert";

// Your code to run since DOM is loaded and ready
$(function() {

  const modalBody = $("#edit-resource-modal .modal-body")

  $('body').on('click', '#edit-resource-link', (event) => {
    event.preventDefault();

    const resourceModel = $(event.target).data('resourceModel')
    const resourceId = $(event.target).data('resourceId')

    modalBody.empty();

    $.get(`/${resourceModel}/${resourceId}/edit`, ( data ) => {
      modalBody.html(data)

      const select = ("#edit-resource-modal .platform-select");
      const prefixMap = $(select).data('urlPrefixes');

      const platformId = $(select).val();

      // if we're editing a platform that has a prefix, modify the displayed value
      if (prefixMap && prefixMap[platformId]) {
        $("#edit-resource-modal .url-prefix.input-group-text").text(prefixMap[platformId]);

        const input = ("#edit-resource-modal .platform-link-input");
        const currentValue = $(input).val();
        $(input).val( currentValue.replace(prefixMap[platformId], '') );
      }

      /* Sortable Deal Images */
      var sortables = document.querySelectorAll('.new-resource-form .sortable');
      sortables.forEach(elm => {
        console.log('Creating sortable');
        Sortable.create(elm, {
          draggable: '.deal-images-sortable',
          dataIdAttr: 'data-sortable-id',
          handle: ".sortable-handle",  // Drag handle selector within list items

          // Changed sorting within list
          onUpdate: function (event) {
            console.log('event', event);
            const images = event.target.querySelectorAll('.deal-image');
            const attachmentIds = Array.from(images).map(elm => elm.dataset.attachmentId)
            const formAction = $("#edit-resource-modal #deal-form").attr('action');

            const token = $('meta[name="csrf-token"]').attr('content');
            $.ajaxSetup({
              beforeSend: function (xhr) {
                xhr.setRequestHeader('X-CSRF-Token', token);
              }
            });

            $.post(`${formAction}/images`, { ordered_image_ids: attachmentIds }).then(() => {
              createAlert('success', 'Image order updated')
            })
          }
        });
      })
      $('body').on('mouseover', '.sortable .list-group-item', function() {
        $(this).find('.sortable-handle').removeClass('invisible').addClass('visible');
      }).on('mouseout', '.list-group-item', () => {
        $(this).find('.sortable-handle').removeClass('visible').addClass('invisible');
      });


      // reinitialize bootstrap tooltips
      intializeBootstrapTooltips();
    });

  });

  $('body').on('click', '.copy-link', function(event) {
    event.preventDefault();

    const profileUrl = event.target.dataset.url;

    navigator.clipboard.writeText(profileUrl).then(function () {
      createAlert('info', 'Link copied')
    });
  })

})


